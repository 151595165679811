<template>
    <div class="footer" :class="[flgHeadFooter ? 'show' : '', showFootMobile ? 'showMobile' : '']">
        <div class="boxRightFooter">
            <button @click="openCrew">MEMBERS</button>
            <h2>© 2023. All rights reserved.</h2>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'openCrew',
            'flgHeadFooter',
            'showFootMobile'
        ],
    }
</script>

<style lang="scss" scoped>
    .footer{
        background: #000;
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        visibility: hidden;
        z-index: 9999;
        text-align: right;
        @media (min-width: 993px){
            transition: all 1s ease;
            opacity: 0;
            left: 50px;
            right: 50px;
            &.show{
                opacity: 1;
                bottom: 0px;
                visibility: visible;
                left: 0;
                right: 0;
                
            }
        }
        @media (max-width: 992px){
            transition: all 1.5s ease;
            opacity: 0;
            bottom: 70px;
            &.showMobile{
                opacity: 1;
                bottom: 0;
                visibility: visible;

            }
        }
        .boxRightFooter{
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            background: #ECECEE;
            width: fit-content;
            margin-left: auto;
            height: 70px;
            button{
                width: 147px;
                border: 0;
                box-shadow: none;
                background: transparent;
                // font-family: 'PP Neue Machina';
                font-family: 'Space Grotesk', sans-serif;
                font-weight: bold;
                font-size: 14px;
                color: #000;
                display: inline-block;
                height: 100%;
                border-right: 1px solid #000;
                img{
                    width: 12px;
                    margin-top: -4px;
                    margin-left: 3px;
                }
            }
            h2{
                width: 365px;
                margin: 0;
                font-weight: normal;
                font-size: 14px;
                text-align: center;
                display: inline-block;
                color: #000000;
                // font-family: 'PP Neue Montreal';
                font-family: 'Space Grotesk', sans-serif;

            }
        }
        
        @media (max-width: 1920px) and (min-width: 993px){
            .boxRightFooter{
                height: 50px;
            }
        }
        @media(max-width: 992px){
            .boxRightFooter{
                height: 55px;
                button{
                    width: 100px;
                }
                h2{
                    width: 225px;
                }

            }
        }
    }
</style>